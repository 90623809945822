<template>
  <b-card>
    <ol class="c3 lst-kix_list_1-0">
      <li class="c8 li-bullet-0">
        <span class="c0">Tambah Agenda</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_11-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu </span><span class="c7">Agenda</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik tombol </span><span class="c7">Tambah Agenda</span>
        <b-img
          alt="D:\user guide\tambah agenda\1.png"
          :src="require('@/assets/images/images-3/image2.png')"
          style="width: 624.00px; height: 335.05px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_11-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Masukkan data agenda yang benar, note: </span>
        <span class="c7">Tanggal Mulai </span><span class="c0">harus diisi lebih besar dari hari dan jam sekarang. Kemudian klik </span><span class="c7">Simpan </span><span class="c0">untuk menyimpan agenda</span>
        <b-img
          alt="D:\user guide\tambah agenda\2.png"
          :src="require('@/assets/images/images-3/image15.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c6">
      <span class="c0" />
    </p>
    <p class="c5">
      <span class="c0" />
    </p>
    <ol
      class="c3 lst-kix_list_1-0"
      start="8"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Edit Agenda</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_12-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu </span><span class="c7">Agenda</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik salah satu agenda yang ingin di-update</span>
        <b-img
          alt="D:\user guide\edit agenda\1.png"
          :src="require('@/assets/images/images-3/image9.png')"
          style="width: 624.00px; height: 334.79px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_12-0">
      <li class="c4 li-bullet-0">
        <span class="c0">Masukkan data agenda yang baru, dan klik </span><span class="c7">Simpan</span>
        <b-img
          alt="D:\user guide\edit agenda\2.png"
          :src="require('@/assets/images/images-3/image18.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c2">
      <span class="c0" />
    </p>
    <p class="c2">
      <span class="c0" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
